import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from "../components/layout";
import Section1 from "../sections/masterclass-section1";
import Section2 from "../sections/masterclass-section2";
import Section3 from "../sections/masterclass-section3";
import SEO from "../components/seo"

export default function Masterclass() {
  return (
    <Layout 
        LogoColor={props => props.theme.colors.secondary_green} 
        HeaderMenuColor={props => props.theme.colors.gray_dark} 
        firstSectionBGColor={props => props.theme.colors.white}

        //button
        HeaderButtonColor={props => props.theme.buttons.primary_small_button_yellow_gray.normal.color} 
        HeaderButtonBorder={props => props.theme.buttons.primary_small_button_yellow_gray.normal.border_color} 
        HeaderButtonBackground={props => props.theme.buttons.primary_small_button_yellow_gray.normal.background_color}
        
        HeaderButtonColorHover={props => props.theme.buttons.primary_small_button_yellow_gray.hovered.color}
        HeaderButtonBorderHover={props => props.theme.buttons.primary_small_button_yellow_gray.hovered.border_color} 
        HeaderButtonBackgroundHover={props => props.theme.buttons.primary_small_button_yellow_gray.hovered.background_color}

        HeaderButtonColorPressed={props => props.theme.buttons.primary_small_button_yellow_gray.pressed.color}
        HeaderButtonBorderPressed={props => props.theme.buttons.primary_small_button_yellow_gray.pressed.border_color} 
        HeaderButtonBackgroundPressed={props => props.theme.buttons.primary_small_button_yellow_gray.pressed.background_color}
      >
          <SEO title={"Masterclass"} description={"A virtual online knowledge-sharing and workshop sessions, tackling professional and personal growth challenges faced by women. All sessions will be hosted by WomenWorks mentors."} />
          <Section1
            isEarlyBird = {false}
          ></Section1>
        <Section2
        ImageFolder="masterclass-bootcamp3"
        >
        </Section2>
        <Section3></Section3>
      </Layout>
  )
}
