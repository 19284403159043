import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import Container from "react-bootstrap/Container";


const SectionBackground = styled.div`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.gray_dark};
  font: 20px/28px Roboto;
  @media(max-width:767px) {
    min-height: 0;
    overflow:hidden;
  }
  @media(min-width:768px) {
    margin-top:85px;
  }
`

const SectionTitle = styled.div`
  font: 58px/62px Abril Fatface;
  margin-bottom:20px;
  color: ${props => props.theme.colors.gray_dark};
  @media(max-width:1199px){
    font: 56px/60px Abril Fatface;
  }
  @media(max-width:767px){
    font: 2.5rem/3rem Abril Fatface;
    margin-bottom:17.5px;
  }
`

const SectionItem = styled.div`
  margin-left: auto;
  margin-right:auto;
  max-width: 964px;
  display:flex;
  flex-direction:column;
  text-align:center;
  margin-bottom:27px;
  @media(max-width:767px){
    margin-top:0;
    margin-bottom:0px;
  }
  @media(min-width:768px){
    margin-bottom: 15px;
  }
`

const Sessions = styled.div`
  padding-top: 80px;
  padding-bottom: 80px;
  display:flex;
  width:100%;
  flex-wrap: wrap;
  justify-content:center;
  @media(max-width:768px) {
    padding-top: 30px;
    padding-bottom: 65px;
  }
  @media(min-width:1200px){
    width: 120%;
    margin-left:-100px;
  }
`
const SectionImage = styled.img`
  margin-bottom:25px;
  height:266px;
  width:213px;
  @media(max-width:455px){
    width:90%;
    height:90%;
  }
`

const ButtonFill1 = styled(Button)`
  height: fit-content;
  align-self: center;
  border-radius: 4px;
  color: ${props => props.theme.buttons.primary_button_violet.normal.color} !important;
  background-color: ${props => props.theme.buttons.primary_button_violet.normal.background_color} !important;
  border-color: ${props => props.theme.buttons.primary_button_violet.normal.border_color} !important;
  font: 22px/22px Roboto;
  width: fit-content;
  padding : 16px 30px 16px 30px;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props => props.theme.buttons.primary_button_violet.hovered.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.hovered.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_violet.hovered.border_color} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.colors.gray_shadow} !important;
      @media(min-width:1199px){
          margin-top:-3px;
          margin-bottom:3px;
      }
  }
  &:focus, &.focus, &:active, &.active {
  color: ${props => props.theme.buttons.primary_button_violet.pressed.color} !important;
  background-color: ${props => props.theme.buttons.primary_button_violet.pressed.background_color} !important;
  border-color: ${props => props.theme.buttons.primary_button_violet.pressed.border_color} !important;
  box-shadow: none !important;
    @media(min-width:1199px){
        margin-top:-3px;
        margin-bottom:3px;
    }
  }
  @media(max-width:767px){
    width:100%;
    margin-bottom:20px;
    font: 22px/22px Roboto;
  }
`


export default function MentorshipSection1(props) {
  return (
  <SectionBackground>
      <Container css={css`display:block;`}>
          <SectionItem>
                <SectionTitle>
                    Our past Classes
                </SectionTitle>
                <SectionItem>
                <p>Follow us on socials to stay tuned to our upcoming Masterclass and Events!</p>
                </SectionItem>
              <ButtonFill1 href={"https://www.instagram.com/womenworksid/"}>
                Follow our Instagram
              </ButtonFill1>
              <Sessions>
                <SectionImage variant="top" src={"/"+props.ImageFolder+"/session-1@2x.png"}/>
                <SectionImage variant="top" src={"/"+props.ImageFolder+"/session-2@2x.png"}/>
                <SectionImage variant="top" src={"/"+props.ImageFolder+"/session-3@2x.png"}/>
                <SectionImage variant="top" src={"/"+props.ImageFolder+"/session-4@2x.png"}/>
                <SectionImage variant="top" src={"/"+props.ImageFolder+"/session-5@2x.png"}/>
              </Sessions>
          </SectionItem>
      </Container>
  </SectionBackground>
  )
}