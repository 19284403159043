import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import Container from "react-bootstrap/Container";

const ThirdSectionBackground = styled.div`
  background-color : ${props => props.theme.colors.gray_light_background};
  color : ${props => props.theme.colors.gray_dark};
  min-height:758px;
`

const ThirdSectionTitle = styled(Col)`
  color : ${props => props.theme.colors.primary_violet};
  margin-top:100px;
  font: 58px/62px Abril Fatface;
  text-align:center;
  margin-bottom:41px;
  @media(max-width:767px){
    font: 2.5rem/3rem Abril Fatface;
    margin-top:50px;
    margin-bottom:35px;
  }
`
const ThirdSection = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const Section2Col = styled(Col)`
   font: 28px/36px Roboto;
   margin-top:50px;
   text-align:center;
   margin-bottom:100px;
   @media(max-width:767px){
    margin-top: 35px;
    margin-bottom:0;
   }
`

const SvgIcon = styled.img`
   margin-bottom: 16px;
`
const ItemTitle = styled.div`
  font: 28px/32px Abril Fatface;
  color: ${props => props.theme.colors.gray_dark};
  margin-bottom:10px;
  min-height:70px;
`

const ItemContent = styled.div`
  font: 20px/28px Roboto;
  color: ${props => props.theme.colors.gray_dark};
`

export default function MasterclassSection3() {
  return (      
  <ThirdSectionBackground>
      <Container>
      <ThirdSection css={css`margin-left:0px;margin-left:0px;`}>
            <Row css={css`justify-content:center;`}>
            <ThirdSectionTitle xs="auto">
                What will you get out of our masterclasses?
            </ThirdSectionTitle>
            </Row>
            <Row>
                <Section2Col xs="12" md="4">
                    <SvgIcon src="/page-masterclass-section3-icon1.svg"/> 
                    <ItemTitle>Live Activities</ItemTitle>
                    <ItemContent>Each of our masterclasses will emphasize on classroom involvement, where mentees will be able to get a live demo or even participate in the workshop’s activities.</ItemContent>
                </Section2Col>
                <Section2Col xs="12" md="4">
                    <SvgIcon src="/page-masterclass-section3-icon2.svg"/> 
                    <ItemTitle>Learning Circle</ItemTitle>
                    <ItemContent>Participants can choose to connect, share and learn from their fellow classmates. Maybe you will meet your future business partners. </ItemContent>
                </Section2Col>
                <Section2Col xs="12" md="4" css={css`@media(max-width:991px){margin-bottom:50px;}`}>
                    <SvgIcon src="/page-masterclass-section3-icon3.svg"/> 
                    <ItemTitle>Giveaways</ItemTitle>
                    <ItemContent>They say learning is a lifetime journey. We want you to leave with applicable tools that you can use to continue learning and expand your growth!</ItemContent>
                </Section2Col>
            </Row>
        </ThirdSection>
      </Container>
  </ThirdSectionBackground>
  )
} 